const state = {
    order_types: {
        "osago": 1,
        "bus_insurance": 2,
        "green_card":3,
        "dgo":4,
        "tourism":5,
        "autolawyer":6,
    },
    all_order_types: [
        { name: 'ОСЦПВ', id: 1},
        { name: 'Зелена Карта', id: 3},
        { name: 'ДЦВ', id: 4},
        { name: 'Туризм', id: 5},
        { name: 'Автоюрист', id: 6},
    ],
};

const getters = {
    ORDER_TYPES: state => state.order_types,
    ALL_ORDER_TYPES: state => state.all_order_types
};

export default {
    state,
    getters
};
