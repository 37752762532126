import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import orderStore from './modules/order_store';
import userInfo from './modules/userInfo_store';
import userSettings from './modules/userData/settings_store';
import osagoStore from './modules/osago/osago_store';
import clientStore from './modules/client/client_store';
import profileInfo from './modules/profileInfo_store';
import partners from "./modules/userData/partner_store";
import authorization from './modules/authorization_store';
import transactionsStore from './modules/transactions_store';
import bannersStore from './modules/banners_store';
import carsStore from './modules/cars_store';
import clientBonusStore from './modules/client_bonus_store';
import autoCategoryTypes from './dictionaries/auto_category_types_store';
import userTypesTariff from './dictionaries/user_types_tariff_store';
import passportCenters from './dictionaries/passport_centers';
import otkTypes from './dictionaries/otk_types_store';
import zoneTypes from './dictionaries/zone_types';
import periodTypes from './dictionaries/periods_store';
import insuranceParametersStore from './dictionaries/insurance_parameters_store';
import autoNumberValidateStore from './dictionaries/auto_number_validate_store';
import documentTypes from './dictionaries/document_types_store';
import franchiseTypes from './dictionaries/franchise_types';
import newTariffItem  from './modules/tariffs/new_tariff_store';
import orderStatuses from "@/store/dictionaries/order_statuses_store";
import clientStatuses from "@/store/dictionaries/clients_tabs_store";
import orderTypes from "@/store/dictionaries/order_types_store";
import customerTypes from '@/store/dictionaries/customer_types_store';
import privilegeTypes from '@/store/dictionaries/privilege_types_store';
import companiesInfo from '@/store/dictionaries/companies_info_store';
import userTypes from '@/store/dictionaries/user_types_store';
import accountCashHistory from '@/store/dictionaries/account_cash_history';
import companyStartDate from '@/store/dictionaries/company_start_date';
import newsStore from '@/store/modules/news_store';
import BusInsuranceCompanyStore from '@/store/modules/bus_insurance_company_store';
import GreenCardInsuranceCompanyStore from '@/store/modules/greencard_insurance_company_store';
import tourismStore from "@/store/modules/tourism/tourism_store";
import levelsStore from "@/store/modules/bonusSystem/levels_store";
import missionsStore from "@/store/modules/bonusSystem/missions_store";
import displaySettingsStore from "@/store/modules/display_settings_store"
import activityTypesStore from "@/store/dictionaries/user_activity_types_store";

// initial state
let initialState = {
    partners: { ...partners.state },
    carsStore: { ...carsStore.state },
    userInfo: { ...userInfo.state },
    authorization: { ...authorization.state },
    osagoStore: { ...osagoStore.state },
    clientStore: { ...clientStore.state },
    profileInfo: { ...profileInfo.state },
    orderStore: { ...orderStore.state },
    transactionsStore: { ...transactionsStore.state },
    bannersStore: { ...bannersStore.state },
    clientBonusStore: { ...clientBonusStore.state },
    BusInsuranceCompanyStore: { ...BusInsuranceCompanyStore },
    GreenCardInsuranceCompanyStore: { ...GreenCardInsuranceCompanyStore },
    tourismStore: { ...tourismStore },
}


Vue.use(Vuex)

export default new Vuex.Store({
  state: {

  },
  mutations: {
      reset (state) {
          //TODO решить почему это не работаетж
          Object.keys(initialState).forEach(key => {
              Object.assign(state[key], initialState[key])
          })
      }
  },
  actions: {

  },
  getters: {

  },
  modules: {
      partners, userInfo, authorization, osagoStore, clientStore, profileInfo, documentTypes,
      orderStore, transactionsStore, bannersStore, clientBonusStore, newTariffItem,
      autoCategoryTypes, userTypesTariff, otkTypes, zoneTypes, periodTypes, franchiseTypes,
      orderStatuses, clientStatuses, orderTypes, carsStore, customerTypes, privilegeTypes, userSettings,
      passportCenters, insuranceParametersStore, autoNumberValidateStore, companiesInfo, userTypes,
      accountCashHistory, companyStartDate, newsStore, BusInsuranceCompanyStore, GreenCardInsuranceCompanyStore, tourismStore, levelsStore, missionsStore, displaySettingsStore, activityTypesStore
  },
  plugins: [
        createPersistedState({
            paths: ['osagoStore', 'clientStore', 'userInfo', 'userTypes', 'newTariffItem', 'BusInsuranceCompanyStore', 'GreenCardInsuranceCompanyStore', 'tourismStore']
        }),
  ]

})
