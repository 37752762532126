import Vue from 'vue'
import Vuelidate from 'vuelidate';
import App from './App.vue';
import './registerServiceWorker';
import store from './store';
import VueMask from 'v-mask';
import router from './router';
import Axios from 'axios';
import money from 'v-money'
import underscore from 'vue-underscore';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './assets/js/bootstrap';
import './assets/js/app/scripts';
import {IconsPlugin } from 'bootstrap-vue';
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ImgInputer from 'vue-img-inputer';
import 'vue-img-inputer/dist/index.css';
import VueCurrencyFilter from 'vue-currency-filter';
import VueSimpleAlert from "vue-simple-alert";
import ElPagination from 'element-ui/lib/pagination';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ua';
import VueEllipsis from 'vue-ellipsis'
import Skeleton from 'vue-loading-skeleton';
import Clipboard from 'v-clipboard'
import VueClipboard from 'vue-clipboard2';
import vueCurrencyDirective from 'vue-currency-directive';
import VueConfirmDialog from 'vue-confirm-dialog/src'


const VueInputMask = require('vue-inputmask').default;

import { faInfo, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faInfo, faEdit, faTrashAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueInputMask);
Vue.use(Skeleton);
Vue.use(Clipboard);
Vue.use(VueClipboard);
Vue.use(VueEllipsis);
Vue.use(VueConfirmDialog)

Vue.component('ImgInputer', ImgInputer)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)


import datePicker from 'vue-bootstrap-datetimepicker';
import ElementUI from "element-ui";

Vue.use(datePicker);
Vue.use(Vuelidate);
Vue.use(IconsPlugin)
Vue.use(BootstrapVue)
Vue.use(ElementUI, { locale });
Vue.use(require('vue-moment'));
Vue.use(money, {precision: 4})
Vue.use(underscore);

Vue.use(VueSimpleAlert);
Vue.directive('currency', vueCurrencyDirective);



Vue.use(VueCurrencyFilter,
    {
        symbol : '₴',
        thousandsSeparator: '.',
        fractionCount: 2,
        fractionSeparator: ',',
        symbolPosition: 'front',
        symbolSpacing: true
    })

Vue.use(VueMask, {
    placeholders: {
        Я: /[а-яА-Я]/, // cyrillic letter as a placeholder
        V: /[a-zA-Z, а-яА-Я]/,
        U: /[0-9,А-Яа-яЁёІіЇїЄє]/,
        Y: /[А-Яа-яЁёІіЇїЄєʼ\s']/,
        K: /[А-Яа-яЁёІіЇїЄє']/,
        G: /[a-zA-ZА-Яа-яЁёІіЇїЄє\s]/,
        N: /[0-9, a-zA-Z\s]/,
        M: /[0-9, a-zA-ZА-Яа-яЁёІіЇїЄє]/
    }
})
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;

Vue.prototype.$http = Axios;


const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' +token
}

Vue.filter('currencySign', function (currencyCode) {
    const currencySigns = {
        'EUR': '€',
        'USD': '$',
        // Добавьте другие коды валют и соответствующие знаки здесь
    };
    return currencySigns[currencyCode] || currencyCode;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
